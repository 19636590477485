
.card-danger .card-header {
  color: rgb(245, 59, 59);
}
[dir] .card-danger .card-header {
  background: #fff0f0;
}
[dir] .card-danger {
  background: #fce7e72e;
  border-color: #fce7e72e;
}
.custom-checkbox-footer label {
  font-size: 0.9rem !important;
}
.container-uploads svg {
  width: 70px;
}
[dir] .b-form-tags.form-control {
  padding: 1rem 1rem !important;
}
.beforeUpload .icon {
  width: 50px !important;
}
.area-lessons {
  min-height: 400px;
}
